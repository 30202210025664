<script setup>
const { initCart, fetchCategories } = useShopStore()

try {
	await Promise.all([initCart(), fetchCategories()])
} catch (error) {
	console.error('Warenkorb-Initalisierung fehlgeschlagen', error)
	throw createError({
		message: 'Warenkorb-Initalisierung fehlgeschlagen',
		error
	})
}

const shopLayout = useShopLayout()

const isSidebarVisible = useSidebarVisibility()
const shopView = ref(null)

useSwipe(shopView, {
	onSwipeEnd (_e, direction) {
		if (direction === 'left') {
			isSidebarVisible.value = false
		}
	}
})

const isLargeScreen = useMediaQuery('(min-width: 900px)')
const isScrollLocked = useScrollLock(window)

watchEffect(() => {
	if (shopLayout.value === 'cart') {
		isSidebarVisible.value = false
		return
	}
	if (isLargeScreen.value && !isSidebarVisible.value) {
		isSidebarVisible.value = true
		isScrollLocked.value = false
	} else if (isLargeScreen.value) {
		isScrollLocked.value = false
	} else if (isSidebarVisible.value) {
		isScrollLocked.value = true
	} else {
		isScrollLocked.value = false
	}
})

const closeSidebar = () => {
	if (isLargeScreen.value) {
		return
	}

	isSidebarVisible.value = false
}

// Add this computed property to detect if we're on a product detail page
const route = useRoute()
const isProductDetail = computed(() => route.name.split('__')[0] === 'shop-product-detail')
</script>

<template>
	<main class="shop-layout grid">
		<LoadingOverlay />
		<ShopHeader />
		<div
			ref="shopView"
			:class="{
				'sidebar-is-visible': isSidebarVisible,
				'is-product-detail': isProductDetail
			}"
			class="shop-view"
			@click.self="closeSidebar"
		>
			<Transition name="slide-inline-start">
				<ShopSidebar v-if="shopLayout !== 'cart'" />
			</Transition>
			<slot />
		</div>
	</main>
</template>

<style>
[data-layout="shop"] {
	--hero-height: 0;

	.header {
		--transition-delay: 1s;
		--background-color: var(--color-paper-white);
		--foreground-color: var(--color-blue);
		--header-height: var(--header-height-collapsed);
		--header-border-color: var(--color-grey);
	}
}
</style>

<style scoped>
.shop-layout {
	z-index: 1;

	min-block-size: calc(100vh - var(--header-height-collapsed));
	margin-block-start: calc(var(--header-height-collapsed) + var(--shop-header-height));

	color: var(--color-blue);

	background-color: var(--color-paper-white);
}

.shop-view {
	display: grid;
	grid-column: full;
	align-items: start;

	margin-block: var(--gap);
	padding-inline: var(--space);

	&::before {
		pointer-events: none;
		content: '';

		position: fixed;
		z-index: 2;
		inset: 0;

		opacity: 0;
		background-color: hsl(from var(--color-black) h s l / 90%);
		backdrop-filter: blur(1.5rem);

		transition: opacity var(--transition-duration) ease;
	}

	& :deep(h1) {
		max-inline-size: 100%;
		margin-block-start: 0;
		text-wrap: pretty;
	}

	& :deep(ul + h1) {
		margin-block-start: var(--gap);
	}

	@media (--sidebar-overlay) {
		&.sidebar-is-visible {
			position: relative;

			&::before {
				pointer-events: auto;
				opacity: 1;
			}
		}
	}

	@media (--medium) {
		grid-column: frame;
		grid-template-columns: minmax(200px, 3fr) minmax(auto, 9fr);
		gap: var(--gap);
		padding-inline: 0;

		&.is-product-detail {
			grid-template-columns: minmax(200px, 2fr) minmax(auto, 10fr);
		}
	}
}

:deep(.price) {
	.separator {
		user-select: none;
		padding-inline: 0.065em;
		font-size: 0.9em;
	}
}

:deep(.formkit-form) {
	--fk-bg-button-hover: var(--color-paper-white);
	--fk-bg-button: var(--color-blue);
	--fk-bg-input: var(--color-white);
	--fk-bg-submit-hover: var(--color-paper-white);
	--fk-bg-submit: var(--color-blue);
	--fk-bg-decorator: var(--color-white);
	--fk-border-box-shadow-focus: 0 0 0 2px var(--color-blue);
	--fk-border-radius: 0;
	--fk-box-size: 1rem;
	--fk-color-border-focus: var(--color-blue);
	--fk-color-border: var(--color-gray);
	--fk-color-button-hover: var(--color-blue);
	--fk-color-button: var(--color-paper-white);
	/* --fk-color-error: var(--color-yellow); */
	--fk-color-danger: var(--color-yellow);
	--fk-color-help: var(--color-black);
	--fk-color-input: var(--color-blue);
	--fk-color-primary: var(--color-blue);
	--fk-font-family: var(--sans-serif);
	--fk-padding-button: 0.6em 0.9em;
	--fk-font-family-input: var(--sans-serif);
	--fk-font-weight-input: var(--font-weight-normal);
	--fk-font-weight-label: var(--font-weight-bold);
	--fk-font-weight: var(--font-weight-normal);
	--fk-font-size-button: var(--font-size-normal);
	--fk-max-width-input: 100%;
	--multistep-color-border: var(--color-grey);
	--multistep-color-success: var(--color-green);
	--multistep-radius: 3px;
	--multistep-shadow: inset 0 -0.25rem 10rem 0 var(--color-white);

	&:not(:has([data-type="multi-step"])) {
		display: grid;
		gap: 1rem;

		> * {
			grid-column: span 2;

			&.half-width {
				grid-column: span 1;
			}
		}
	}

	::selection {
		color: var(--color-paper-white);
		background-color: var(--color-blue);
	}

	h2 {
		margin-block-end: var(--space);
		font-size: var(--font-size-large);
	}

	[data-type="multi-step"] > .formkit-wrapper {
		display: grid;
		gap: var(--gap);
		max-inline-size: 100%;
	}

	[data-type="multi-step"].formkit-outer {
		> [data-tab-style="progress"] {
			> .formkit-tabs {
				.formkit-tab {
					&::after {
						width: 0;
						transition: width var(--transition-duration) ease;
					}

					&[data-valid="true"][data-visited="true"]::after {
						width: 100%;
					}
				}
			}
		}
	}

	.formkit-outer[data-type="multi-step"] {
		& > .formkit-wrapper {
			max-width: none;
		}

		:deep(.formkit-step-actions) {
			gap: var(--gap);
			margin-block-start: var(--gap);
		}
	}

	.formkit-outer[data-type="multi-step"] > .formkit-wrapper .formkit-fieldset {
		background-color: var(--color-paper-white);
	}

	.formkit-steps {
		display: grid;
		gap: var(--gap);

		.formkit-step-actions {
			margin-block-start: var(--gap);

			.formkit-outer {
				margin-block-end: 0;
			}
		}
	}

	.formkit-input {
		line-height: 1.5;

		&[type="password"] {
			font-family: var(--monospace);
			font-size: var(--font-size-normal);
			letter-spacing: 0.25ch;
		}
	}

	[data-type="checkbox"] fieldset.formkit-fieldset,
	[data-type="radio"] fieldset.formkit-fieldset {
		border: 1px solid var(--color-grey);
	}

	[data-type="checkbox"] .formkit-input ~ .formkit-decorator,
	[data-type="radio"] .formkit-input ~ .formkit-decorator {
		display: flex;
		place-content: center;
		place-items: center;
	}

	[data-type="checkbox"] .formkit-input ~ .formkit-decorator .formkit-icon,
	[data-type="radio"] .formkit-input ~ .formkit-decorator .formkit-icon {
		position: static;
		transform: none;
	}

	.formkit-icon svg {
		overflow: visible;
	}

	[data-type="button"] .formkit-input,
	[data-type="submit"] .formkit-input {
		margin: 0;

		font-weight: var(--font-weight-bold);
		color: var(--color-paper-white);

		background-color: var(--color-blue);
		border: 1px solid var(--color-blue);

		transition-duration: var(--transition-duration);
		transition-property: color, background-color, box-shadow;

		&:hover,
		&:focus {
			color: var(--color-blue);
			background-color: var(--color-paper-white);
		}
	}

	[data-floating-label="true"] {
		.formkit-label {
			padding: 0.5ch 1ch;

			font-weight: var(--font-weight-bold);
			text-align: center;

			background-color: var(--color-white) !important;
			backdrop-filter: blur(0);
			border: 1px solid transparent;
			border-radius: 0.4ch;

			transition: var(--transition-duration) 400ms;
		}

		&:not([data-empty="true"]) .formkit-label,
		&[data-expanded="true"] .formkit-label,
		input:focus ~ .formkit-label,
		textarea:focus ~ .formkit-label {
			transform: translateY(-75%);

			background-color: color-mix(in srgb, var(--color-white), transparent 80%) !important;
			backdrop-filter: blur(1rem);
			border-color: var(--color-blue);

			transition-delay: 0s;
		}
	}

	.formkit-suffix-icon {
		background-color: var(--color-white);
	}

	.formkit-step-inner {
		display: grid;
		grid-template-columns: 1fr 1fr;
		row-gap: 1rem;
		column-gap: 2rem;

		> * {
			grid-column: span 2;

			&.half-width {
				grid-column: span 1;
			}
		}
	}

	.formkit-message {
		display: flex;
		align-items: center;
		line-height: 1;

		svg {
			transform: translateX(-0.2em) translateY(-0.05em);
		}
	}

	@media (--medium) {
		grid-template-columns: 1fr 1fr;
	}
}

:deep(.shop-tile) {
	display: grid;
	gap: 1rem;
	align-content: start;

	height: 100%;
	padding: 1rem;

	background-color: var(--color-paper-white);
	border: 1px solid var(--color-grey);
	border-radius: 3px;
	box-shadow: inset 0 -0.25rem 10rem 0 var(--color-white);
}

/*
:deep(button.formkit-input) {
	font-family: var(--sans-serif);
	font-size: 1rem;
	font-weight: var(--font-weight-normal);
	color: var(--color-white);

	background-color: var(--color-blue);
	border-radius: 0;
}
*/
</style>
