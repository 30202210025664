<script setup>
import AccountIcon from 'assets/images/account.svg'
import CartIcon from 'assets/images/cart.svg'
import CloseIcon from 'assets/images/close.svg'
import SearchIcon from 'assets/images/search.svg'

const {
	customer,
	cartItemsCount,
	categoryFilter,
	productFilter
} = storeToRefs(useShopStore())

const isSearchVisible = useSearchVisibility()
const toggleSearchVisibility = () => {
	// isSearchVisible.value = !isSearchVisible.value

	if (!isSearchVisible.value) {
		productFilter.value = ''
		categoryFilter.value = ''
	}
}

const isLoggedIn = computed(() => !!customer.value)

const cartCount = computed(() => {
	if (cartItemsCount.value > 99) {
		return '99'
	}
	return cartItemsCount.value
})

const addingToCart = ref(false)
watch(cartItemsCount, () => {
	addingToCart.value = true
	useTimeoutFn(() => {
		addingToCart.value = false
	}, 2000)
})
</script>

<template>
	<TransitionGroup
		tag="ul"
		class="toolbar"
		name="grid"
	>
		<li
			v-if="!isSearchVisible"
			key="search"
			class="search"
			@click.prevent="toggleSearchVisibility"
		>
			<NuxtLink to="/shop/suche">
				<SearchIcon />
			</NuxtLink>
		</li>
		<li
			v-if="!isSearchVisible"
			key="account"
			class="account"
			:class="{ 'logged-in': isLoggedIn }"
		>
			<NuxtLink to="/shop/konto">
				<AccountIcon />
			</NuxtLink>
		</li>
		<li
			v-if="!isSearchVisible"
			key="cart"
			class="cart"
			:class="{ adding: addingToCart }"
		>
			<NuxtLink to="/shop/warenkorb">
				<CartIcon />
				<div
					v-if="cartItemsCount > 0"
					:class="{ 'over-99': cartItemsCount > 99 }"
					class="cart-item-count"
				>
					{{ cartCount }}
				</div>
			</NuxtLink>
		</li>
		<li
			v-else
			key="close"
			class="close"
			@click.prevent="toggleSearchVisibility"
		>
			<CloseIcon />
		</li>
	</TransitionGroup>
</template>

<style scoped>
.toolbar {
	--size: 1.5rem;

	display: grid;
	grid-auto-flow: column;
	grid-column: frame;
	grid-row: 1;
	gap: var(--size);
	justify-self: end;

	list-style: none;

	li {
		cursor: pointer;
		display: grid;
		place-items: center;
		font-weight: var(--font-weight-bold);

		&.cart {
			transition: var(--transition-duration);

			&.adding,
			&:hover {
				--cart-items-count-scale: 1.5;
				--cart-items-count-translate-y: -0.5rem;
				--cart-items-count-plus-translate-x: 55%;
				--cart-items-count-plus-translate-y: 15%;
				--icon-opacity: 0.4;
			}

			&.adding,
			&:active {
				--icon-opacity: 0.2;
				--cart-items-count-translate-y: -0.1rem;
				--cart-items-count-scale: 1.4;
			}

			&.adding {
				--icon-opacity: 0;
				--cart-items-count-translate-y: 0;
				--cart-items-count-scale: 1.75;
				--cart-items-count-background-color: var(--color-blue);
				--cart-items-count-before-opacity: 0;
				--cart-items-count-before-delay: 0s;
				--cart-items-count-before-scale: 0.2;
				--cart-items-count-color: var(--color-paper-white);
				--cart-items-count-plus-translate-x: 65%;
				--cart-items-count-plus-translate-y: 25%;
				--cart-items-count-plus-color: var(--color-blue);
			}
		}

		&.unavailable {
			cursor: not-allowed;
			font-weight: var(--font-weight-normal);
			opacity: 0.5;
		}

		a {
			position: relative;

			display: grid;
			place-content: center;
			place-items: center;

			width: var(--size);
			height: var(--size);
			padding: calc(var(--size) / 2);
		}

		svg {
			overflow: visible;

			width: auto;
			height: var(--size);

			opacity: var(--icon-opacity, var(--icon-opacity, 1));

			transition: opacity var(--transition-duration) var(--ease-in-out-cubic);

			:deep(path) {
				fill: var(--color-paper-white);
				stroke: var(--icon-color, var(--color-blue));
				transition: var(--transition);
			}
		}

		&.logged-in {
			svg {
				:deep(path) {
					fill: color-mix(in srgb, var(--color-blue), transparent 40%);
				}
			}
		}

		.cart-item-count {
			position: absolute;
			inset: 0;
			transform:
				translateX(-37%) translateY(var(--cart-items-count-translate-y, -28%)) scale(var(--cart-items-count-scale, 1));

			display: grid;
			place-content: center;
			place-items: center;

			width: 2em;
			height: 2em;
			margin-inline: 20%;

			font-size: var(--font-size-tiny);
			font-weight: var(--font-weight-bold);
			line-height: 1;
			color: var(--cart-items-count-color, var(--color-blue));
			text-align: center;
			letter-spacing: -0.04ch;
			white-space: nowrap;

			background-color: var(--cart-items-count-background-color, transparent);
			border-radius: 50%;

			transition: var(--transition-duration);

			&::before {
				content: '';

				position: absolute;
				z-index: -1;
				inset: 0 24% 15%;
				transform-origin: bottom;
				transform-style: preserve-3d;
				transform: perspective(2rem) rotateX(315deg) scale(var(--cart-items-count-before-scale, 1));

				margin: auto;

				opacity: var(--cart-items-count-before-opacity, 1);
				background-image: linear-gradient(to bottom, var(--cart-items-count-background-color, var(--color-paper-white)) 50%, transparent);
				box-shadow: 0 0 2px 1px var(--cart-items-count-background-color, var(--color-paper-white));

				transition: opacity var(--transition-duration) var(--cart-items-count-before-delay, 200ms);
			}

			&.over-99::after {
				content: '+';

				position: absolute;
				inset: 0;
				transform-origin: center;
				transform: translateX(var(--cart-items-count-plus-translate-x, 120%)) translateY(var(--cart-items-count-plus-translate-y, 5%));

				font-size: 1em;
				font-weight: var(--font-weight-normal);
				line-height: 1;
				color: var(--cart-items-count-plus-color, var(--color-blue));
				text-align: center;
				letter-spacing: 0;

				transition: var(--transition-duration);
			}
		}
	}
}
</style>
