<script setup>
import IconArrow from 'assets/images/arrow.svg'

const isSidebarVisible = useSidebarVisibility()
const toggleSidebar = () => {
	isSidebarVisible.value = !isSidebarVisible.value
}

const shopLayout = useShopLayout()
const toggleLabel = computed(() => {
	return shopLayout.value === 'account' ? 'Konto' : 'Kategorien'
})
</script>

<template>
	<div class="shop-sidebar-toggle">
		<button
			class="shop-sidebar-toggle-button"
			@click="toggleSidebar"
		>
			<IconArrow :class="{ 'is-rotated': isSidebarVisible }" />
			<span v-text="toggleLabel" />
		</button>
	</div>
</template>

<style scoped>
.shop-sidebar-toggle {
	button {
		cursor: pointer;

		display: flex;
		gap: 2ch;
		place-items: center;

		height: var(--shop-header-height);

		font-size: var(--font-size-normal);
		font-weight: var(--font-weight-normal);

		svg {
			width: 4rem;

			transition-delay: var(--transition-duration);
			transition-timing-function: var(--ease-out-quart);
			transition-duration: var(--transition-duration);
			transition-property: transform;

			&.is-rotated {
				transform: rotateY(180deg);
			}
		}

		span {
			@media (width < 300px) {
				display: none;
			}
		}
	}

	@media (--medium) {
		display: none;
	}
}
</style>
