<script setup>
const isSidebarVisible = useSidebarVisibility()
</script>

<template>
	<nav :class="{ 'sidebar-is-visible': isSidebarVisible }">
		<ul
			class="customer-navigation"
			@click="isSidebarVisible = !isSidebarVisible"
		>
			<li class="customer-index-page">
				<NuxtLink to="/shop">
					<strong><span>Zum Shop</span></strong>
				</NuxtLink>
			</li>
			<li class="customer-index-page">
				<NuxtLink to="/shop/konto">
					<strong><span>Konto</span></strong>
				</NuxtLink>
			</li>
			<li>
				<NuxtLink to="/shop/konto/profil">
					<span>Profil</span>
				</NuxtLink>
			</li>
			<li>
				<NuxtLink to="/shop/konto/bestellungen">
					<span>Bestellungen</span>
				</NuxtLink>
			</li>
			<li>
				<NuxtLink to="/shop/konto/lieferadresse">
					<span>Lieferadresse</span>
				</NuxtLink>
			</li>
		</ul>
	</nav>
</template>

<style scoped>
nav {
	@media (--sidebar-overlay) {
		padding: var(--gap);
	}
}

.customer-navigation {
	list-style: none;
}

.customer-index-page {
	margin-block-end: 1rem;
	font-size: var(--font-size-medium);
}
</style>
