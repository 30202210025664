<script setup>
import IconChevron from 'assets/images/chevron.svg'

const findFirstCategoryWithProducts = (category) => {
	// Wenn aktuelle Kategorie Produkte hat, diese zurückgeben
	if (category.metadata.product_count > 0) {
		return category
	}

	// Wenn keine Kinder vorhanden sind, null zurückgeben
	if (!category.children?.length) {
		return null
	}

	// Rekursiv durch Kinder suchen
	for (const child of category.children) {
		const result = findFirstCategoryWithProducts(child)
		if (result) {
			return result
		}
	}

	return null
}

const getCategoryNavigationTarget = (category) => {
	if (category.metadata.product_count > 0) {
		return `/shop/kategorie/${category.handle}`
	}

	const targetCategory = findFirstCategoryWithProducts(category)
	return targetCategory
		? `/shop/kategorie/${targetCategory.handle}`
		: `/shop/kategorie/${category.handle}` // Fallback zur ursprünglichen Kategorie
}

const props = defineProps({
	category: {
		type: Object,
		required: true
	},
	mainCategory: {
		type: Boolean,
		default: false
	}
})

const { category } = props
const { children } = category

const navigationTarget = computed(() => {
	return getCategoryNavigationTarget(category)
})
</script>

<template>
	<li
		:key="category.id"
		:class="{
			'has-sub-categories': children?.length,
			'is-main-category': mainCategory
		}"
		class="category"
	>
		<NuxtLink
			:to="navigationTarget"
			class="category-link"
		>
			<IconChevron
				v-if="children?.length"
				class="chevron"
			/>
			<span v-text="category.name" />
		</NuxtLink>
		<ul
			v-if="children?.length"
			class="sub-categories"
		>
			<ShopCategoryItem
				v-for="subCategory in children"
				:key="`category-${subCategory.id}`"
				:category="subCategory"
			/>
		</ul>
	</li>
</template>

<style scoped>
/* stylelint-disable no-descending-specificity */

.category {
	cursor: pointer;

	font-size: var(--font-size-medium);
	font-weight: var(--font-weight-bold);
	hyphens: auto;
	list-style: none;

	&.is-main-category {
		transform: translateX(0);

		> .category-link {
			grid-template-columns: 1fr;
		}
	}

	> .category-link {
		display: grid;
		grid-template-columns: 1rem 1fr;
		align-items: baseline;

		&.router-link-exact-active {
			font-weight: var(--font-weight-bold);
		}

		.chevron {
			transform: translate(-0.25rem, -0.1rem) rotate(var(--chevron-rotate, -90deg));

			width: 0.75rem;

			opacity: var(--chevron-opacity, 0);

			transition-timing-function: var(--transition-timing-function);
			transition-duration: var(--transition-duration);
			transition-property: transform;

			:deep(path) {
				will-change: stroke-width;
				stroke: var(--color-blue);
				stroke-width: var(--chevron-stroke-width, 3);
				transition: stroke-width var(--transition-duration) var(--transition-timing-function);
			}
		}
	}

	.category {
		font-size: var(--font-size-small);
		font-weight: var(--font-weight-normal);

		span {
			transform: scale(var(--scale, 1));
			grid-column: 1 / -1;
			transition: transform var(--transition-duration) var(--transition-timing-function);
		}

		&.has-sub-categories {
			--chevron-opacity: 1;

			will-change: grid-template-rows;

			position: relative;

			display: grid;
			grid-template-rows: auto 0fr;

			transition-delay: calc(var(--transition-duration) * 0.5);
			transition-timing-function: var(--ease-in-out-quart);
			transition-duration: calc(var(--transition-duration) * 1.5);
			transition-property: grid-template-rows;

			span {
				grid-column: 2 / -1;
			}

			&:has(.router-link-exact-active) {
				--sub-categories-opacity: 1;

				grid-template-rows: auto 1fr;
				font-weight: var(--font-weight-bold);
				transition-delay: 0s;

				> .category-link > .chevron {
					--chevron-rotate: 0deg;
				}
			}

			.sub-categories {
				will-change: opacity;

				overflow: hidden;

				margin-block: 0.1rem;
				margin-inline-start: -1rem;
				padding-inline-start: 1.5rem;

				font-size: var(--font-size-extra-small);

				opacity: var(--sub-categories-opacity, 0);

				transition-delay: var(--transition-duration);
				transition-timing-function: var(--transition-timing-function);
				transition-duration: var(--transition-duration);
				transition-property: opacity;
			}
		}
	}
}
</style>
