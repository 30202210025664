<script setup>
const { $loading } = useNuxtApp()
</script>

<template>
	<Transition name="fade">
		<div
			v-if="$loading"
			class="loading-overlay"
		>
			<div class="loader" />
		</div>
	</Transition>
</template>

<style scoped>
.loading-overlay {
	position: fixed;
	z-index: 9999;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;

	display: flex;
	align-items: center;
	justify-content: center;

	background: rgba(255, 255, 255, 0.8);
}

.loader {
	width: 48px;
	height: 48px;

	border: 4px solid #f3f3f3;
	border-top: 4px solid var(--color-blue);
	border-radius: 50%;

	animation: spin 1s linear infinite;
}

@keyframes spin {
	0% {
		transform: rotate(0deg);
	}

	100% {
		transform: rotate(360deg);
	}
}

.fade-enter-active,
.fade-leave-active {
	transition: opacity 0.3s ease;
}

.fade-enter-from,
.fade-leave-to {
	opacity: 0;
}
</style>
