<script setup>
import IconArrow from 'assets/images/arrow.svg'

const isHeaderVisible = useHeaderVisibility()

const shopLayout = useShopLayout()
</script>

<template>
	<TransitionGroup
		tag="header"
		:class="{ 'header-is-visible': isHeaderVisible }"
		class="shop-header grid"
		name="fade"
		appear
	>
		<ShopSidebarToggle
			v-if="shopLayout !== 'cart'"
			key="ShopSidebarToggle"
		/>
		<NuxtLink
			v-else
			key="BackToShop"
			to="/shop"
			class="back-to-shop"
		>
			<IconArrow class="arrow" />
			<span>zum Shop</span>
		</NuxtLink>
		<ShopToolbar key="ShopToolbar" />
	</TransitionGroup>
</template>

<style scoped>
.shop-header {
	position: fixed;
	z-index: 4;
	inset-block-start: 0;
	inset-inline: 0;

	display: grid;
	grid-column: full;
	align-content: center;
	align-items: center;

	height: var(--shop-header-height);
	padding-block: 1rem;

	background-color: var(--color-paper-white);
	border-block: 1px solid var(--color-grey);

	transition-delay: 0s;
	transition-timing-function: var(--ease-out-quart);
	transition-duration: calc(var(--transition-duration) * 1);
	transition-property: inset-block-start;

	&::before {
		pointer-events: none;
		content: '';

		position: absolute;
		inset-block: auto 100%;
		inset-inline: 0;

		display: block;

		width: 100vw;
		height: var(--header-height);
		margin: auto;

		background-image: linear-gradient(to bottom, transparent, var(--color-paper-white));
	}

	&.header-is-visible {
		inset-block-start: var(--header-height-collapsed);
		transition-delay: calc(var(--transition-duration) * 0.45);
		transition-timing-function: var(--ease-in-out-quad);
	}

	@media (--sidebar-overlay) {
		grid-template-columns: auto auto;
		padding-inline: var(--space);
	}

	@media (width < 400px) {
		padding-inline: var(--gap);
	}
}

.back-to-shop {
	display: flex;
	grid-row: 1;
	gap: 2ch;
	align-items: center;
	justify-self: start;

	svg {
		rotate: 180deg;
		width: 4rem;
	}

	@media (--medium) {
		grid-column: frame;
	}
}
</style>
