<script setup>
import { toRefs, computed, watch } from 'vue'
import { useShopStore } from '@/stores/shop'
import { useShopLayout, useSidebarVisibility } from '@/composables/useInterface'

const shopStore = useShopStore()
const { categories, productsInCategory } = toRefs(shopStore)

const shopLayout = useShopLayout()
const shopOverviewLabel = computed(() => {
	return shopLayout.value === 'products'
		? 'Alle Kategorien'
		: '← Zu den Produkten'
})

const isSidebarVisible = useSidebarVisibility()
const isScrollLocked = useScrollLock(window)
const isLargeScreen = useMediaQuery('(min-width: 900px)')
const closeSidebarOnSmallScreens = () => {
	if (!isLargeScreen.value) {
		isSidebarVisible.value = false
		isScrollLocked.value = false
	}
}

watch(productsInCategory, () => {
	isSidebarVisible.value = false
	isScrollLocked.value = false
})
</script>

<template>
	<ul
		v-if="categories"
		class="categories"
	>
		<li
			class="shop-home"
			@click="closeSidebarOnSmallScreens"
		>
			<NuxtLink
				to="/shop"
				class="shop-home-link"
			>
				<span>{{ shopOverviewLabel }}</span>
			</NuxtLink>
		</li>
		<ShopCategoryItem
			v-for="category in categories"
			:key="`main-category-${category.id}`"
			:category="category"
			:main-category="true"
			@click="isSidebarVisible = false"
		/>
	</ul>
	<div v-else>
		Keine Kategorien vorhanden
	</div>
</template>

<style>
.app:has(.header.unpinned) {
	--categories-height: 100vh;
	--categories-inset-block-start: calc(var(--space) * -1);
}

.app:has(.header.pinned) {
	--categories-height: calc(100vh - var(--header-height-collapsed));
	--categories-inset-block-start: 0;
}
</style>

<style scoped>
.shop-home-link {
	justify-self: start;
	font-size: var(--font-size-medium);
	font-weight: var(--font-weight-bold);
	hyphens: auto;
}

.categories,
.sub-categories {
	user-select: none;
	margin: 0;
	padding: 0;
	list-style: none;
}

.categories {
	scrollbar-color: var(--color-blue) var(--color-white);
	scrollbar-width: thin;

	overflow-y: auto;
	display: flex;
	flex-direction: column;
	gap: 2rem;

	padding: var(--gap);
	padding-bottom: 10rem;

	transition: var(--transition-duration) var(--transition-timing-function);

	> :deep(.category > .sub-categories) {
		margin-block-start: 1rem;

		@media (--small) {
			margin-block-start: 0.5rem;
		}
	}

	@media (--small) {
		padding-block-start: var(--space);
		padding-inline: var(--gap);
	}

	@media (--medium) {
		overflow: visible;
		padding: 0;
	}
}

.button {
	position: absolute;
	right: var(--space);
	bottom: calc(var(--space) * 3);
	left: var(--space);

	display: block;

	padding: 1rem;

	text-align: center;

	box-shadow: 0 0 10px 10px var(--color-gray);
}

@keyframes shake {
	0% {
		transform: translateY(0);
	}

	25% {
		transform: translateY(-5px);
	}

	50% {
		transform: translateY(5px);
	}

	75% {
		transform: translateY(-5px);
	}

	100% {
		transform: translateY(0);
	}
}

.shake {
	animation: shake 0.5s ease;
}
</style>
