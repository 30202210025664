<script setup>
const shopLayout = useShopLayout()

const isHeaderVisible = useHeaderVisibility()
const isSidebarVisible = useSidebarVisibility()

const isLargeScreen = useMediaQuery('(min-width: 900px)')
watch(isLargeScreen, () => {
	if (shopLayout.value === 'cart') {
		isSidebarVisible.value = false
		return
	}
	isSidebarVisible.value = false
}, { immediate: true })
</script>

<template>
	<TransitionGroup
		name="slide"
		mode="in-out"
		tag="aside"
		:class="{
			'header-is-visible': isHeaderVisible,
			'sidebar-is-visible': isSidebarVisible,
			'sidebar-is-hidden': !isSidebarVisible
		}"
		class="shop-sidebar"
		appear
	>
		<ShopCustomerNavigation
			v-if="shopLayout === 'account'"
			key="account"
		/>
		<ShopCategories
			v-else
			key="categories"
		/>
	</TransitionGroup>
</template>

<style scoped>
.shop-sidebar {
	position: fixed;
	z-index: 3;
	inset: var(--shop-header-height) 0 0 0;

	overflow-y: clip;
	display: grid;

	background-color: var(--color-paper-white);
	border-inline-end: 1px solid var(--color-grey);

	transition-delay: 0s;
	transition-timing-function: var(--ease-out-quint);
	transition-duration: calc(var(--transition-duration) * 1.25);
	transition-property: inset-block-start, translate;

	&::before {
		pointer-events: none;
		content: '';

		position: absolute;
		inset: 0 0 0 auto;
		translate: 100%;

		width: var(--gap);

		background-image:
			linear-gradient(to right,
				hsl(from var(--color-black) h s l / 25%),
				hsl(from var(--color-black) h s l / 0%));

		transition-delay: calc(var(--transition-duration) * 1.5);
		transition-duration: calc(var(--transition-duration) * 1.5);
		transition-property: opacity;
	}

	&.sidebar-is-visible {
		translate: 0;
		/* overflow-y: hidden; */

		@media (--medium) {
			position: static;
			overflow: visible;
			min-inline-size: auto;
			border-inline-end: hsl(from var(--color-grey) h s l / 0%);

			&::before {
				display: none;
			}
		}
	}

	&.sidebar-is-hidden {
		pointer-events: none;
		translate: -100% 0;

		&::before {
			opacity: 0;
			transition-delay: calc(var(--transition-duration) * 2);
		}
	}

	&.header-is-visible {
		inset-block-start: calc(var(--header-height-collapsed) + var(--shop-header-height));
		transition-delay: calc(var(--transition-duration) * 0.55);
		transition-timing-function: var(--ease-in-out-quad);
	}

	@media (--extra-small) {
		inset-inline-end: auto;
		min-inline-size: 25rem;
	}
}
</style>
